// Added Sept 2022 - PHOS Creative
document.addEventListener("DOMContentLoaded", () => {
  const formWrapper = document.querySelector(".gform_wrapper ");
  const validationError = document.querySelector(".validation_error");

  formWrapper &&
    validationError &&
    setTimeout(() => {
      formWrapper.scrollIntoView({ block: "start", inline: "nearest" });
    }, 1500);
});
